<script setup lang="ts">
import { computed, inject, InputHTMLAttributes, toRef } from 'vue';
import { useField } from 'vee-validate';

export interface IInputProps extends /* @vue-ignore */ InputHTMLAttributes {
  name: string;
  size?: string;
  wrapperClass?: string;
  disabled?: boolean;
  required?: boolean;
  nativeValue?: string | number | boolean | object | [] | symbol | null;
  modelValue?: string | number | boolean | object | [] | symbol | null;
  standalone?: boolean;
}

export interface IInputEmits {
  (e: 'update:modelValue', value: string | number | boolean | object | [] | symbol | null): void;
}

const props = defineProps<IInputProps>();
const emit = defineEmits<IInputEmits>();

const isGrouped = inject<boolean>('isGrouped', false);
const name = toRef(props, 'name', '');

const {
  value: inputValue,
  errorMessage: error,
  handleBlur,
  handleChange,
} = useField(name, undefined, {
  standalone: props.standalone,
  initialValue: props.modelValue,
  validateOnValueUpdate: false,
  syncVModel: true,
});

const inputValueModel = computed({
  get: () => inputValue.value,
  set: (value) => {
    handleChange(value, true);
    emit('update:modelValue', value);
  },
});
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <div :class="wrapperClass">
    <label class="flex radio cursor-pointer items-center">
      <input
        v-model="inputValueModel"
        type="radio"
        v-bind="$attrs"
        :value="nativeValue"
        :disabled="disabled"
        @blur="handleBlur"
      />

      <span
        v-if="$slots.default"
        class="ml-10"
      >
        <slot />
      </span>
    </label>

    <p
      v-if="error && !isGrouped"
      class="text-small mt-10"
      :class="[error ? 'text-danger' : 'text-gray-dark']"
    >
      <slot name="message">
        {{ error }}
      </slot>
    </p>
  </div>
</template>
